import { SelectChangeEvent } from "@mui/material/Select";
import axios from "../../shared/axios-util";
import { useEffect, useState } from "react";
import { API_ROUTES } from "../../shared/constants/routes";
import {
  IconCircleInformation,
  IconCircleWarn,
  useSnackbar,
} from "@kushki/connect-ui";
import { Webhook } from "../../shared/interfaces/Webhook.interface";
import {
  OptionsEnum,
  TRANSACTION_STATUS,
  WebhookEventsEnum,
  WebhookServiceEnum,
} from "../../shared/constants/enums/OptionsEnum";

const enum DEFAULT_VALUES {
  CODE = "{}",
  EMPTY_STRING = "",
}

type BasicFormValue =
  | DEFAULT_VALUES.EMPTY_STRING
  | (WebhookEventsEnum | OptionsEnum);
type CodeEditor = DEFAULT_VALUES.CODE | string;

interface TransactionTypeField {
  label: string;
  value: string;
}

const enum SNACKBAR_COLOR {
  DANGER = "danger",
  INFO = "info",
}

interface Card {
  url: string;
  status: "OK" | "FAILED";
  statusResponse: string;
}

interface TestConnection {
  card: Card[];
}

interface HookArgs {
  handleClose: () => void;
  webhook: Webhook;
}

const paymentMethodMappings = {
  [WebhookServiceEnum.card]: OptionsEnum.CARD,
  [WebhookServiceEnum.cash]: OptionsEnum.CASH,
  [WebhookServiceEnum["payouts-card"]]: OptionsEnum.PAYOUTS_CARD,
  [WebhookServiceEnum["payouts-cash"]]: OptionsEnum.PAYOUTS_CASH,
  [WebhookServiceEnum["payouts-transfer"]]: OptionsEnum.PAYOUTS_TRANSFER,
  [WebhookServiceEnum.transfer]: OptionsEnum.TRANSFER,
  [WebhookServiceEnum["transfer-subscriptions"]]: OptionsEnum.RECURRENT_DEBITS,
  [WebhookServiceEnum["card-chargeback"]]: OptionsEnum.CHARGEBACK,
  [WebhookServiceEnum.subscriptions]: OptionsEnum.SUBSCRIPTIONS,
  [WebhookServiceEnum.vpos]: OptionsEnum.VIRTUAL_POS,
  [WebhookServiceEnum.webcheckout]: OptionsEnum.SMARTLINKS,
};

export const useWebhookSimulationModal = ({
  handleClose,
  webhook,
}: HookArgs) => {
  const [paymentMethodFields, setPaymentMethodFields] = useState<OptionsEnum[]>(
    []
  );

  const [transactionTypeFields, setTransactionTypeFields] = useState<
    TransactionTypeField[]
  >([
    {
      label: TRANSACTION_STATUS.approvedTransaction,
      value: WebhookEventsEnum.approvedTransaction,
    },
    {
      label: TRANSACTION_STATUS.declinedTransaction,
      value: WebhookEventsEnum.declinedTransaction,
    },
  ]);

  const [code, setCode] = useState<CodeEditor>(DEFAULT_VALUES.CODE);
  const [paymentMethod, setPaymentMethod] = useState<BasicFormValue>(
    DEFAULT_VALUES.EMPTY_STRING
  );
  const [transactionType, setTransactionType] = useState<BasicFormValue>(
    DEFAULT_VALUES.EMPTY_STRING
  );
  const [disableSimulateConnectionButton, setDisableSimulateConnectionButton] =
    useState(true);

  const [testConnectionResult, setTestConnectionResult] = useState<
    undefined | Card[]
  >();

  const [testConnectionLoading, setTestConnectionLoading] = useState(false);

  useEffect(() => {
    if (!webhook) return;
    if (!webhook.events) return;

    const paymentMethods = webhook.events.map((e) => e.service!);
    const uniquePaymentMethods = Array.from(new Set(paymentMethods));

    const mappedPaymentMethods = uniquePaymentMethods.map(
      (p) => paymentMethodMappings[p]
    );

    setPaymentMethodFields(mappedPaymentMethods);
  }, [webhook]);

  useEffect(() => {
    if (!webhook) return;
    if (!webhook.events) return;
    const [firstField] = transactionTypeFields;
    const secondField =
      paymentMethod === OptionsEnum.CHARGEBACK
        ? {
            label: TRANSACTION_STATUS.chargeback,
            value: WebhookEventsEnum.chargeback,
          }
        : {
            label: TRANSACTION_STATUS.declinedTransaction,
            value: WebhookEventsEnum.declinedTransaction,
          };
    const thirdField = {
      label: TRANSACTION_STATUS.cashPreathorization,
      value: WebhookEventsEnum.cashPreathorization,
    };
    const transactionFields =
      paymentMethod === OptionsEnum.CASH
        ? [firstField, secondField, thirdField]
        : [firstField, secondField];

    setTransactionTypeFields(transactionFields);
  }, [paymentMethod]);

  const handlePaymentMethodChange = (e: SelectChangeEvent<string>) =>
    setPaymentMethod(e.target.value as OptionsEnum);

  const handleCodeChange = (code: string) => setCode(() => code);
  const handleTransactionTypeChange = (e: React.BaseSyntheticEvent) =>
    setTransactionType(e.target.value as WebhookEventsEnum);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const value =
      paymentMethod === DEFAULT_VALUES.EMPTY_STRING ||
      transactionType === DEFAULT_VALUES.EMPTY_STRING;

    setDisableSimulateConnectionButton(value);
  }, [paymentMethod, transactionType]);

  const resetState = () => {
    setPaymentMethod(DEFAULT_VALUES.EMPTY_STRING);
    setTransactionType(DEFAULT_VALUES.EMPTY_STRING);
    setCode(DEFAULT_VALUES.CODE);
  };

  const closeModal = () => {
    resetState();
    handleClose();
  };

  const handleSubmit = async () => {
    if (disableSimulateConnectionButton) return;
    if (!webhook || !webhook.status || !webhook.events) return;

    setTestConnectionLoading(true);
    const urls = webhook.status.map((s) => s.url);

    try {
      const { data } = await axios.post<TestConnection>(
        API_ROUTES.TEST_CONNECTION,
        {
          webhookData: [
            {
              body: code,
              kindTransaction: transactionType,
              service: paymentMethod,
              urls,
            },
          ],
        }
      );

      showSnackbar({
        color: SNACKBAR_COLOR.INFO,
        icon: <IconCircleInformation />,
        message: "Se ha simulado la conexión con éxito.",
        variant: "simple",
        withIcon: true,
      });
      setTestConnectionResult(data.card);
    } catch {
      showSnackbar({
        color: SNACKBAR_COLOR.DANGER,
        icon: <IconCircleWarn />,
        message:
          "La simulación de la conexión ha fallado, inténtalo nuevamente.",
        variant: "simple",
        withIcon: true,
      });
    } finally {
      setTestConnectionLoading(false);
      closeModal();
    }
  };

  return {
    disableSimulateConnectionButton,
    editor: {
      handleChange: handleCodeChange,
      value: code,
    },
    handleSubmit,
    paymentMethod: {
      fields: paymentMethodFields,
      handleChange: handlePaymentMethodChange,
      value: paymentMethod,
    },
    testConnectionResult: {
      data: testConnectionResult,
      loading: testConnectionLoading,
    },
    transactionType: {
      fields: transactionTypeFields,
      handleChange: handleTransactionTypeChange,
      value: transactionType,
    },
  };
};
