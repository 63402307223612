/* istanbul ignore file */
import axios from "axios";
import { environment } from "../environments/environment";

const instance = axios.create({
  baseURL: environment.kushkiUrl,
  headers: {
    Authorization: localStorage.getItem("jwt") || "",
    "Content-type": "application/json",
  },
});

export default instance;
